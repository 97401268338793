import React from "react"
import PropTypes from "prop-types"
import Footer from "../components/Footer"
import Header from "../components/Header"
import style from "./ImprintLayout.module.scss"

const ImprintLayout = props => {
  const { children } = props

  return (
    <>
      <main className={style.main}>{children}</main>

      <Header />

      <Footer />
    </>
  )
}

ImprintLayout.propTypes = {
  children: PropTypes.node,
}

export default ImprintLayout
