import React from "react"
import { graphql } from "gatsby"
import JobTeaser from "../components/JobTeaser"
import RichTextSlice from "../components/RichTextSlice"
import SEO from "../components/SEO"
import ImprintLayout from "../layouts/ImprintLayout"
import { SLICE } from "../misc/constants"

export default props => {
  const {
    _meta,
    site_title,
    site_description,
    site_image,
    body,
  } = props.data.prismic.allImprints.edges[0].node

  return (
    <ImprintLayout>
      <SEO
        title={site_title}
        description={site_description}
        image={site_image}
        lang={_meta.lang}
        slug={_meta.uid}
      />

      {body &&
        body.map((slice, index) => {
          switch (slice.type) {
            case SLICE.RICH_TEXT:
              return (
                <RichTextSlice
                  key={index}
                  richText={slice.primary.rich_text}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.JOB_TEASER:
              return (
                <JobTeaser
                  key={index}
                  heading1={slice.primary.title}
                  job1={slice.primary.job_01}
                  job2={slice.primary.job_02}
                  job3={slice.primary.job_03}
                  relatedPage={slice.primary.related_page._meta.uid}
                  relatedHeadline={slice.primary.related_headline_title}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            default:
              return null
          }
        })}
    </ImprintLayout>
  )
}

export const query = graphql`
  query ImprintQuery {
    prismic {
      allImprints {
        edges {
          node {
            _meta {
              uid
              lang
            }
            title
            site_title
            site_description
            site_image
            body {
              ... on PRISMIC_ImprintBodyRich_text {
                type
                primary {
                  rich_text
                  spacing_bottom
                }
              }
              ... on PRISMIC_ImprintBodyJob_teaser {
                type
                primary {
                  title
                  job_01 {
                    ... on PRISMIC_Job {
                      title
                      alternative_title
                      workload
                    }
                  }
                  job_02 {
                    ... on PRISMIC_Job {
                      title
                      alternative_title
                      workload
                    }
                  }
                  job_03 {
                    ... on PRISMIC_Job {
                      title
                      alternative_title
                      workload
                    }
                  }
                  related_headline_title
                  related_page {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                  }
                  spacing_bottom
                }
              }
            }
          }
        }
      }
    }
  }
`
